import InternWelcomePage from './InternWelcomePage';
import InternDatesOverview from './DatesOverview/InternDatesOverview';
import InternYouthpartysPage from './coursePage/starters/InternYouthpartysPage';
import InternClubfinder from './clubfinder/InternClubfinder';
import InternSpecialCoursesPage from './coursePage/starters/InternSpecialCoursesPage';
import InternRefreshsPage from './coursePage/starters/InternRefreshsPage';
import InternTCEEventsPage from './coursePage/starters/InternTCEEventsPage';
import InternWorkshopsPage from './coursePage/starters/InternWorkshopsPage';
import MembershipInfoPage from '../MembershipInfo/MembershipInfoPage';
import AboutPage from '../about/AboutPage';
import EditTanzabendPage from './DatesOverview/Edit/EditTanzabendPage';
import EditFerienPage from './DatesOverview/Edit/EditFerienPage';
import EditClubPage from './clubfinder/EditClubPage';
import ReservationsPage from './Reservations/ReservationsPage';
import EditCoursePage from './coursePage/edit/EditCoursePage';
import Intern404Page from './Intern404Page';

const internRoutesConfig = [
  { path: '', Component: InternWelcomePage },
  { path: 'dates', Component: InternDatesOverview },
  { path: 'youthDates', Component: InternYouthpartysPage },
  { path: 'clubfinder', Component: InternClubfinder },
  { path: 'specialCourses', Component: InternSpecialCoursesPage },
  { path: 'refresh', Component: InternRefreshsPage },
  { path: 'tceevents', Component: InternTCEEventsPage },
  { path: 'workshops', Component: InternWorkshopsPage },
  { path: 'membershipInfos', Component: MembershipInfoPage },
  { path: 'about', Component: AboutPage },
  { path: 'newTanzabend', Component: EditTanzabendPage },
  { path: 'newCourse', Component: EditCoursePage },
  { path: 'newHoliday', Component: EditFerienPage },
  { path: 'newClub', Component: EditClubPage },
  { path: 'editTanzabend/:id', Component: EditTanzabendPage },
  { path: 'editCourse/:id', Component: EditCoursePage },
  { path: 'editHoliday/:id', Component: EditFerienPage },
  { path: 'editClub/:id', Component: EditClubPage },
  { path: 'reservations/:id', Component: ReservationsPage },
  { path: '*', Component: Intern404Page }
];

export default internRoutesConfig;
