import styles from './InternWelcomePage.module.css';
import { useNavigate } from 'react-router-dom';

export default function Intern404Page() {
  const navigate = useNavigate();
  return (
    <div className={styles.welcomePageContainer}>
      <div className={styles.box}>
        <h1>404.</h1>
        <p>Die angeforderte Seite konnte nicht gefunden werden.</p>
        <p onClick={() => navigate('/intern')} className={styles.link}>
          Hier geht es zurück!
        </p>
      </div>
    </div>
  );
}
