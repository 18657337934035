import CustomCircularProgress from '../../../util/customLoadingSpinner';
import InternCoursePaper from './paper/InternCoursePaper';
import React, { useEffect, useState } from 'react';
import { AllCoursesDTO, CourseDTO } from '../../../model/CourseDTO';
import styles from './InternCoursePage.module.css';
import SearchBar from '../../../component/SearchBar';
import removeDuplicate from '../../../util/removeDuplicate';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { Checkbox, styled } from '@mui/material';
import { CategoryType } from './edit/EditCoursePage';
import { useNavigate } from 'react-router-dom';

type CoursePageProps = {
  courses: AllCoursesDTO | null;
  loading: boolean;
  error: string | null;
  pageTitle: string;
  reload: () => void;
};

const YellowCheckbox = styled(Checkbox)({
  color: '#ffcc00',
  '&.Mui-checked': {
    color: '#ffcc00'
  }
});

export default function InternCoursePage({
  courses,
  loading,
  pageTitle,
  reload,
  category
}: CoursePageProps & { category: CategoryType }) {
  const navigate = useNavigate();

  const [displayedItems, setDisplayedItems] = useState<CourseDTO[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [onlyFuture, setOnlyFuture] = useState(true);

  useEffect(() => {
    const removePast = (list: CourseDTO[]) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (onlyFuture) {
        return list.filter((it) => new Date(it.startDate) >= today);
      }
      return list;
    };

    if (courses) {
      setDisplayedItems([]);
      removePast(courses.allCourses).forEach((item, index) => {
        setTimeout(() => {
          setDisplayedItems((currentItems) => [...currentItems, item]);
        }, 50 * index); // 100ms Verzögerung für jedes Element
      });
    }
  }, [onlyFuture, courses]);

  const reloadFunction = () => {
    setDisplayedItems([]);
    reload();
  };

  const matchesSearchTerm = (course: CourseDTO) => {
    return (
      course.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.topic.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  if (loading || courses === null) {
    return <CustomCircularProgress />;
  }

  return (
    <div>
      <div className={styles.searchContainer}>
        <div className={styles.titleAndPlusContainer}>
          <h1>
            <span className={'grayText'}>{pageTitle}</span>
            <span className={'whiteText'}>.</span>{' '}
          </h1>
          {pageTitle !== 'Sonstiges' && (
            <IconButton
              className={styles.iconButtons}
              onClick={() => navigate('/intern/newCourse')}>
              <AddIcon />
            </IconButton>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '2em', alignItems: 'center' }}>
          <div>
            <YellowCheckbox checked={onlyFuture} onChange={() => setOnlyFuture(!onlyFuture)} />
            <span>Nur zukünftige</span>
          </div>
          <SearchBar onSearchTermChange={setSearchTerm} />
        </div>
      </div>
      <div className={styles.paperContainer}>
        {removeDuplicate<CourseDTO>(displayedItems, (a, b) => a.id === b.id)
          .filter((it) => matchesSearchTerm(it))
          .map((item, index) => (
            <div key={index} className={'fadeIn'}>
              <InternCoursePaper
                course={item}
                reload={reloadFunction}
                category={category}
                navigate={navigate}
              />
            </div>
          ))}
        {displayedItems.filter((it) => matchesSearchTerm(it)).length === 0 ? (
          <p className={styles.emptyState}>
            Aktuell gibt es leider keine Einträge, die zu den gewählten Kriterien passen
          </p>
        ) : null}
      </div>
    </div>
  );
}
