import styles from './InternDatesOverview.module.css';
import CustomCircularProgress from '../../../util/customLoadingSpinner';

import InternFerienDetails from './Details/InternFerienDetails';
import { TanzabendDTO } from '../../../model/TanzabendDTO';
import { FerienDTO } from '../../../model/FerienDTO';
import InternTanzabendDetails from './Details/InternTanzabendDetails';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import ErrorPage, {
  ERROR_CODE_INTERN_DATES_OVERVIEW_FERIEN,
  ERROR_CODE_INTERN_DATES_OVERVIEW_TANZABENDE
} from '../../../error/ErrorPage';
import removeDuplicate from '../../../util/removeDuplicate';
import useInternTanzabende from '../../../hooks/useInternTanzabende';
import useInternFerien from '../../../hooks/useInternFerien';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export default function InternDatesOverview() {
  const navigate = useNavigate();

  const { tanzabende, tanzabendeError, loadTanzabende, reloadTanzabende } = useInternTanzabende();
  const { ferien, error, loading, reloadFerien } = useInternFerien();

  const [displayedTanzabende, setDisplayTanzabende] = useState<TanzabendDTO[]>([]);

  useEffect(() => {
    setDisplayTanzabende([]);
    if (tanzabende) {
      tanzabende.allCourses.forEach((item, index) => {
        setTimeout(() => {
          setDisplayTanzabende((currentItems) => [...currentItems, item]);
        }, 50 * index);
      });
    }
  }, [tanzabende]);

  const [displayedFerien, setDisplayedFerien] = useState<FerienDTO[]>([]);
  useEffect(() => {
    setDisplayedFerien([]);
    if (ferien) {
      ferien.allHoliday.forEach((item, index) => {
        setTimeout(() => {
          setDisplayedFerien((currentItems) => [...currentItems, item]);
        }, 50 * index);
      });
    }
  }, [ferien]);

  useEffect(() => {
    reloadTanzabende();
    reloadFerien();
  }, [reloadFerien, reloadTanzabende]);

  if (tanzabendeError)
    return (
      <ErrorPage location={ERROR_CODE_INTERN_DATES_OVERVIEW_TANZABENDE} error={tanzabendeError} />
    );
  if (error) return <ErrorPage location={ERROR_CODE_INTERN_DATES_OVERVIEW_FERIEN} error={error} />;

  if (loading || loadTanzabende || ferien === null || tanzabende === null) {
    return <CustomCircularProgress />;
  }
  return (
    <div className={styles.welcomePageContentContainer}>
      <RightSide tanzabende={displayedTanzabende} navigate={navigate} />
      <LeftSide ferien={displayedFerien} navigate={navigate} />
    </div>
  );
}

function RightSide({
  tanzabende,
  navigate
}: {
  tanzabende: TanzabendDTO[];
  navigate: NavigateFunction;
}) {
  return (
    <div className={styles.rightSide}>
      <div className={styles.rightUpperSide}>
        <div className={styles.headingContainer}>
          <h1>
            <span className={'grayText'}>Die nächsten </span>
            <span className={styles.priceYellow}>Tanzabende</span>
            <span className={'whiteText'}>.</span>
          </h1>
          <IconButton
            className={styles.iconButtons}
            onClick={() => navigate(`/intern/newTanzabend`)}>
            <AddIcon />
          </IconButton>
        </div>
        <p>
          Der Tanzabend gibt allen Paaren die Möglichkeit, das Gelernte in netter und entspannter
          Gesellschaft anderer Paare, in schönem Ambiente und zu abwechslungsreicher Musik
          auszuprobieren und zu festigen.{' '}
        </p>
        <p>Eine Reservierung ist für den Tanzabend nicht notwendig.</p>
        <p>
          Preis für Mitglieder im Premium Club-System:{' '}
          <span className={styles.priceYellow}> kostenfrei</span>
        </p>
        <p>
          Preis für alle anderen Mitglieder der Tanzschule:{' '}
          <span className={styles.priceYellow}>€8,50/Person</span>
        </p>
        <p>
          Preis für Nicht-Mitglieder: <span className={styles.priceYellow}>€12/Person</span>
        </p>
        <p style={{ color: '#ffcc00' }}>Änderungen vorbehalten</p>
      </div>

      <div className={styles.termineListContainer}>
        <ul className={styles.termineListe}>
          {removeDuplicate<TanzabendDTO>(tanzabende, (a, b) => a.id === b.id).map((tanzabend) => (
            <div className={'fadeIn'}>
              <InternTanzabendDetails tanzabend={tanzabend} navigate={navigate} />
            </div>
          ))}
          {tanzabende.length === 0 ? <h2>Aktuell sind keine Termine geplant</h2> : null}
        </ul>
      </div>
    </div>
  );
}

function LeftSide({ ferien, navigate }: { ferien: FerienDTO[]; navigate: NavigateFunction }) {
  return (
    <div className={styles.rightSide}>
      <div className={styles.rightUpperSide}>
        <div className={styles.headingContainer}>
          <h1>
            <span className={'grayText'}>Wir machen </span>
            <span className={styles.priceYellow}>Ferien</span>
            <span className={'whiteText'}>.</span>
          </h1>
          <IconButton className={styles.iconButtons} onClick={() => navigate('intern/newHoliday')}>
            <AddIcon />
          </IconButton>
        </div>
        <p>An den folgenden Terminen hat unsere Tanzschule geschlossen</p>
        <p>In diesen Zeiten findet kein regulärer Tanzunterricht statt. </p>
        <p>Unser Büro ist nicht besetzt, die Beantwortung von Mails kann sich verzögern.</p>
      </div>

      <div className={styles.termineListContainer}>
        <ul className="termineListe">
          {removeDuplicate<FerienDTO>(ferien, (a, b) => a.id === b.id).map((ferien) => (
            <div className={'fadeIn'}>
              <InternFerienDetails ferien={ferien} navigate={navigate} />
            </div>
          ))}
          {ferien.length === 0 ? <h2>Aktuell sind keine Ferien geplant</h2> : null}
        </ul>
      </div>
    </div>
  );
}
