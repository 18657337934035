import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom'; // Verwende Outlet statt Routes
import styles from './InternPage.module.css';
import Signet from '../../assets/signet_tce.png';
import { useAuth } from '../../context/AuthContext';
import LoginPage from './Login/LoginPage';
import DrawerItems from './DrawerItems';
import { Drawer, Box } from '@mui/material';
import { Menu, Close } from '@mui/icons-material';

export default function InternPage() {
  const { isLoggedIn, logout } = useAuth();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const navigate = useNavigate();

  if (!isLoggedIn) {
    return <LoginPage />;
  }

  return (
    <div>
      <Header
        logout={logout}
        toggleDrawer={() => setDrawerOpen(!drawerOpen)}
        isDrawerOpen={drawerOpen}
      />

      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} anchor={'right'}>
        <Box
          sx={{
            width: 300,
            backgroundColor: '#ffcc00',
            height: '100vh'
          }}
          role="presentation">
          <DrawerItems navigateTo={navigate} closeDrawer={() => setDrawerOpen(false)} />
        </Box>
      </Drawer>

      <main>
        <Outlet />
      </main>
    </div>
  );
}

function Header({
  logout,
  toggleDrawer,
  isDrawerOpen
}: {
  logout: () => void;
  toggleDrawer: () => void;
  isDrawerOpen: boolean;
}) {
  return (
    <header className={styles.internPageHeader}>
      <div className={styles.internPageImageContainer}>
        <img alt="logo" src={Signet} />
        <h1>
          <span className={'yellowText'}>tce</span>
          <span className={'grayText'}>
            vent<span className={'whiteText'}>.</span>
          </span>
        </h1>
      </div>
      <nav className={styles.internPageNavigationBar}>
        <ul>
          <li>
            <button className="link-button" onClick={logout}>
              Logout
            </button>
          </li>
          <li>
            {isDrawerOpen ? <Close onClick={toggleDrawer} /> : <Menu onClick={toggleDrawer} />}
          </li>
        </ul>
      </nav>
    </header>
  );
}
