import { TanzabendDTO } from '../../../../model/TanzabendDTO';
import styles from './InternTanzabendDetails.module.css';
import { toLocalDateWithDayString } from '../../../../util/toLocalDateString';
import IconButton from '@mui/material/IconButton/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { NavigateFunction } from 'react-router-dom';

type TanzabendDetailsProps = {
  tanzabend: TanzabendDTO;
  navigate: NavigateFunction;
};

export default function InternTanzabendDetails({ tanzabend, navigate }: TanzabendDetailsProps) {
  const handleClick = () => navigate(`/intern/editTanzabend/${tanzabend.id}`);

  return (
    <div className={styles.tanzabendDetailContainer}>
      <div className={styles.tanzabendDetailTimeContainer}>
        <p>{toLocalDateWithDayString(tanzabend.date)}</p>
        <p> - </p>
        <p className={styles.detailTime}>{tanzabend.time}</p>
      </div>
      <p>{tanzabend.title}</p>
      <IconButton onClick={handleClick}>
        <EditIcon />
      </IconButton>
    </div>
  );
}
