import styles from './InternClubPaperStyles.module.css';
import getDifficultyColor from '../../../util/getDifficultyColor';
import { ClubDTO } from '../../../model/ClubDTO';
import IconButton from '@mui/material/IconButton/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { NavigateFunction } from 'react-router-dom';

type ClubPaperProps = {
  club: ClubDTO;
  navigate: NavigateFunction;
};

export default function InternClubPaper({ club, navigate }: ClubPaperProps) {
  const handleClick = () => {
    navigate(`/intern/editClub/${club.id}`);
  };

  return (
    <div
      className={styles.tanzabendDetailContainer}
      style={{ border: `6px solid ${getDifficultyColor(club.level)}` }}>
      <div className={styles.tanzabendDetailTimeContainer}>
        <p style={{ color: '#fff' }}>{club.day}</p>
        <p style={{ color: '#fff' }}>-</p>
        <p style={{ color: '#fff' }}>{club.time}</p>
        <p />
        <p style={{ color: '#ffcc00' }}>Kursleiter: {club.teacher}</p>
      </div>
      <IconButton onClick={handleClick}>
        <EditIcon />
      </IconButton>
    </div>
  );
}
