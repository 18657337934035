import styles from './AboutPage.module.css';

export default function AboutPage() {
  return (
    <div className={styles.mainDiv}>
      <h1 className={styles.yellowSpan}>
        tce<span className={styles.whiteSpan}>vent</span>
      </h1>
      <a href={'mailto:kontakt@ettlingen-tanzt.de?subject=Kontaktaufnahme%20über%20tcevent'}>
        Bürokontakt
      </a>
      <p>
        <span>Unsere Telefonischen Bürozeiten:</span> <br />
        Mo. +Di. + Do.: 12:00 - 19:00
        <br />
        Mi.: 12:00 - 15:00
        <br />
        Fr.-So. sind keine Bürozeiten!
        <br />
        <a href={'tel:+49724379829'}>0724379829</a>
      </p>
      <p>
        <a
          href={
            'mailto:stefan.geiselhart@ettlingen-tanzt.de?subject=Kontaktaufnahme%20über%20tcevent'
          }>
          Supportkontakt
        </a>
      </p>
      <p>
        <a href={'https://www.tanzcentrum-ettlingen.de/impressum'}>Impressum</a>
      </p>
      <p>
        <a href={'https://www.tanzcentrum-ettlingen.de/datenschutz'}>Datenschutzerklärung</a>
      </p>
      <p>TanzCentrum Ettlingen GmbH</p>
      <p>
        Betriebsstätte: Robert-Bosch-Straße 5 <br />
        76275 Ettlingen
      </p>
    </div>
  );
}
