import InternCoursePage from '../InternCoursePage';
import useInternCourses from '../../../../hooks/useInternCourses';

export default function InternYouthpartysPage() {
  const { courses, loading, error, reload } = useInternCourses('intern/youthpartys');
  return (
    <InternCoursePage
      courses={courses}
      loading={loading}
      error={error}
      pageTitle={'Jugendpartys'}
      category={'youthparty'}
      reload={reload}
    />
  );
}
