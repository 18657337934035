import { Button, Paper } from '@mui/material';
import styles from './ReservationPaper.module.css';
import { ReservationsDTO } from '../../../../model/ReservationsDTO';
import LogoutIcon from '@mui/icons-material/Logout';

type CoursePaperProps = {
  reservation: ReservationsDTO;
  deleteFunction: (id: string) => unknown;
};

export default function ReservationPaper({ reservation, deleteFunction }: CoursePaperProps) {
  const handleClick = () => {
    deleteFunction(reservation.id);
  };
  return (
    <Paper variant={'outlined'} className={styles.paper}>
      <div className={styles.contentContainer}>
        <div className={styles.noSpacingContainer}>
          <h2>
            {reservation.customer_name_him} {reservation.customer_surname_him}
          </h2>
          <h2>
            {' '}
            {reservation.customer_name_her} {reservation.customer_surname_her}
          </h2>
          <h2> {reservation.membership_type}</h2>
          <p>
            {' '}
            Email: <a href={`mailto:${reservation.customer_email}`}>{reservation.customer_email}</a>
          </p>
          {reservation.customer_mobile ? (
            <p>
              {' '}
              Telefon:{' '}
              <a href={`tel:${reservation.customer_mobile}`}>{reservation.customer_mobile}</a>
            </p>
          ) : (
            <p> Telefon: nicht angegeben</p>
          )}
        </div>
        <div className={styles.addressContainer}>
          {reservation.customer_street &&
          reservation.customer_housenumber &&
          reservation.customer_postal &&
          reservation.customer_local ? (
            <div>
              <p>
                {' '}
                {reservation.customer_street} {reservation.customer_housenumber}
              </p>
              <p>
                {reservation.customer_postal} {reservation.customer_local}
              </p>
            </div>
          ) : (
            <div>Keine Adresse angegeben</div>
          )}
        </div>
        <div className={styles.addressContainer}>
          <div>
            <h3>Anmerkungen:</h3>
            <p>{reservation.remarks}</p>
          </div>
        </div>
        <div className={styles.ButtonContainer}>
          <div>
            <Button className={styles.deleteButton} onClick={handleClick}>
              Abmelden <LogoutIcon className={styles.logoutIcon} />
            </Button>
          </div>
        </div>
      </div>
    </Paper>
  );
}
