import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Overview from './features/Overview/Overview';
import './AppRouter.css';
import RegistrationSite from './features/registration/RegistrationSite';
import { AuthProvider } from './context/AuthContext';
import { ConfigProvider, useConfigContext } from './context/ConfigContext';
import Newsfeed from './features/News/Newsfeed';
import NewsfeedOverview from './features/News/Intern/NewsfeedOverview';
import NewNewsForm from './features/News/Intern/Form/NewNewsForm';
import InternPage from './features/intern/InternPage';
import WelcomePage from './features/Overview/WelcomePage';
import DatesOverview from './features/DatesOverview/DatesOverview';
import Clubfinder from './features/clubfinder/Clubfinder';
import TCEEventsPage from './features/coursePage/TCEEventsPage';
import WorkshopsPage from './features/coursePage/WorkshopsPage';
import RefreshsPage from './features/coursePage/RefreshsPage';
import MembershipInfoPage from './features/MembershipInfo/MembershipInfoPage';
import SuccessPage from './features/success/SuccessPage';
import YouthpartyPage from './features/coursePage/YouthpartyPage';
import SpecialCoursesPage from './features/coursePage/SpecialCoursesPage';
import AboutPage from './features/about/AboutPage';
import { useEffect } from 'react';
import internRoutesConfig from './features/intern/internRoutesConfig';

const routesConfig = [
  {
    path: '/courses',
    component: SpecialCoursesPage,
    configKey: 'TCECOURSES_ENABLED'
  },
  {
    path: '/jugend',
    component: YouthpartyPage,
    configKey: 'YOUTH_DATES_ENABLED'
  },
  {
    path: '/refresh',
    component: RefreshsPage,
    configKey: 'REFRESH_ENABLED'
  },
  {
    path: '/workshops',
    component: WorkshopsPage,
    configKey: 'WORKSHOPS_ENABLED'
  },
  {
    path: '/events',
    component: TCEEventsPage,
    configKey: 'EVENTS_ENABLED'
  },
  {
    path: '/clubfinder',
    component: Clubfinder,
    configKey: 'CLUBFINDER_ENABLED'
  },
  {
    path: '/dates',
    component: DatesOverview,
    configKey: 'DATES_ENABLED_WEB'
  },
  {
    path: '/membership-infos',
    component: MembershipInfoPage,
    configKey: 'MEMBERSHIP_INFOS_ENABLED'
  },
  {
    path: '/success',
    component: SuccessPage,
    configKey: null // Keine Prüfung für SuccessPage
  },
  {
    path: '/about',
    component: AboutPage,
    configKey: 'ABOUT_ENABLED'
  }
];

export default function AppRouter() {
  return (
    <ConfigProvider>
      <BrowserRouter>
        <Routes>
          {routesConfig.map(({ path, component: Component, configKey }) => (
            <Route
              key={path}
              path={path}
              element={
                <ProtectedRoute configKey={configKey}>
                  <BackgroundAndFooter>
                    <Overview>
                      <Component />
                    </Overview>
                  </BackgroundAndFooter>
                </ProtectedRoute>
              }
            />
          ))}
          <Route
            path="/intern/*"
            element={
              <AuthProvider>
                <InternPage />
              </AuthProvider>
            }>
            {internRoutesConfig.map((route) => (
              <Route path={route.path} element={<route.Component />} />
            ))}
          </Route>
          <Route
            path="/reserve/:id"
            element={
              <BackgroundAndFooter>
                <RegistrationSite />
              </BackgroundAndFooter>
            }
          />
          <Route path="/NewsFeed" element={<Newsfeed />} />
          <Route
            path="/newNews"
            element={
              <AuthProvider>
                <BackgroundAndFooter>
                  <NewNewsForm />
                </BackgroundAndFooter>
              </AuthProvider>
            }
          />
          <Route
            path="/NewsFeedOverview"
            element={
              <AuthProvider>
                <BackgroundAndFooter>
                  <NewsfeedOverview />
                </BackgroundAndFooter>
              </AuthProvider>
            }
          />
          <Route
            path="*"
            element={
              <BackgroundAndFooter>
                <Overview>
                  <WelcomePage />
                </Overview>
              </BackgroundAndFooter>
            }
          />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

function ProtectedRoute({
  configKey,
  children
}: {
  configKey: string | null;
  children: JSX.Element;
}) {
  const navigate = useNavigate();
  const config = useConfigContext();

  useEffect(() => {
    if (configKey && config[configKey] !== 'true') {
      navigate('/');
    }
  }, [configKey, config, navigate]);

  return children;
}

function BackgroundAndFooter({ children }: any) {
  return (
    <div className="backgroundImage">
      {children}
      <footer>
        <div className="footerContainer">
          <div className="staticKram">
            <span>TanzCentrum Ettlingen</span>
            <span className="white">Betriebsstätte: Robert-Bosch-Straße 5, 76275 Ettlingen</span>
          </div>
          <div className="linkContainer">
            <a href="https://www.tanzcentrum-ettlingen.de/impressum">Impressum</a>
            <a href="https://www.tanzcentrum-ettlingen.de/datenschutz">Datenschutzerklärung</a>
            <a href="https://www.tanzcentrum-ettlingen.de/kontakt">Kontakt</a>
          </div>
        </div>
      </footer>
    </div>
  );
}
