import InternCoursePage from '../InternCoursePage';
import useInternCourses from '../../../../hooks/useInternCourses';

export default function InternRefreshsPage() {
  const { courses, loading, error, reload } = useInternCourses('intern/refreshs');
  return (
    <InternCoursePage
      courses={courses}
      loading={loading}
      error={error}
      pageTitle={'Refresh'}
      category={'refresh'}
      reload={reload}
    />
  );
}
