import styles from './InfoSection.module.css';
import { Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useConfigContext } from '../../context/ConfigContext';

export default function InfoSection({ pageTitle }: { pageTitle: string }) {
  return (
    <div className={styles.infoContainer}>
      <div className={styles.infoContainerFlex}>
        <div style={{ flex: 1 }}>
          <InfoText pageTitle={pageTitle} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
          <p
            style={{
              fontSize: '17',
              border: '#c0c0c0 solid 2px',
              padding: '1em',
              borderRadius: 10
            }}>
            <span style={{ color: '#ffcc00' }}>Unsere telefonischen Bürozeiten:</span>
            <br />
            Mo. + Di. + Do.: 12:00 – 18:00
            <br />
            Mi. : 12:00 – 15:00
            <br />
            Fr. - So. ist keine Bürozeit!
          </p>
        </div>
      </div>
    </div>
  );
}

function InfoText({ pageTitle }: { pageTitle: string }) {
  const config = useConfigContext();

  if (pageTitle === 'Club-Finder') {
    return (
      <div>
        <p>Ausschließlich für Mitglieder im Premium Club-System</p>
        <p>
          Als Premium-Mitglied darfst Du mehrfach die Woche mit Deinem Partner/in zum Unterricht
          kommen.
          <br />
          Wie finde ich aber die für mich passenden Club-Termine?
        </p>
        <ul>
          <li>
            <p>Finde in der untenstehende Liste Deinen angestammten Clubtermin.</p>
          </li>
          <li>
            <p>
              Du kannst alle anderen Clubtermine besuchen, die entweder die gleiche Farbcodierung
              haben, oder aber im Ton heller sind als Dein Termin.
            </p>
          </li>
        </ul>
        <p className={styles.color}>Änderungen vorbehalten!</p>
      </div>
    );
  }
  if (pageTitle === 'Refreshtanzen') {
    return (
      <div>
        <p>
          Das 60 minütige Refreshtanzen ist für alle Paare geeignet, die sich bei Schritten/Figuren
          ihres Kursprogrammes unsicher fühlen und mit einer gezielten Unterstützung kleine Probleme
          in den Griff bekommen wollen.
          <br /> Für größere Lücken, die entstehen, wenn man längere Zeit in seinem Kurs gefehlt hat
          und einzelne Figuren im Unterricht nicht mitbekommen hat, empfehlen wir das Vereinbaren
          von Privatstunden.
        </p>
        <p>
          Preis für Mitglieder im Premium Club-System: {config.REFRESH_PRICE_MEMBER_PREMIUM} <br />
          Preis für alle anderen Mitglieder der Tanzschule: {config.REFRESH_PRICE_MEMBER}
          <br />
          Teilnahme nur für aktiv tanzende Mitglieder!
        </p>
        <p>
          Die einzelnen Refresh-Termine finden nur bei genügend Reservierungen statt. <br />
          <b>
            <br />
            Bei einem <span style={{ color: '#fc0' }}>gelben</span> Ampelsymbol ist der gewünschte
            Kurs buchbar, aber die Mindestteilnehmerzahl, damit der Kurs stattfinden kann, noch
            nicht erreicht.
            <br />
            Ein stattfindender Kurs ist durch das <span style={{ color: 'green' }}>grüne</span>{' '}
            Symbol gekennzeichnet. Auch für stattfindende{' '}
            <span style={{ color: 'green' }}>(grüne)</span> Kurse ist eine Anmeldung zwingend
            notwendig.
            <br />
            <span style={{ color: 'red' }}>Rot</span> gekennzeichnete Kurse werden nicht
            stattfinden.
          </b>
        </p>
        <br />
        <p>
          {' '}
          Anmeldeschluss ist jeweils {config.DAYS_REGISTRATION_DEADLINE_REFRESH} Tage vor Beginn.
          {'\n'}
          In diesen {config.DAYS_REGISTRATION_DEADLINE_REFRESH} Tagen vor Beginn ist auch eine
          kostenfreie Stornierung der Teilnahme bei bereits erfolgter Reservierung nicht mehr
          möglich.
        </p>
        <p>Eine Anmeldung ist nur paarweise möglich.</p>
        <p className={styles.color}>Änderungen vorbehalten!</p>
      </div>
    );
  }
  if (pageTitle === 'Workshops') {
    return (
      <div>
        <p>
          Unsere Workshops beschäftigen sich intensiv mit einzelnen Tänzen und Themen aus der
          vielfältigen Welt des Tanzens. <br />
          Auf dem Programm stehen z.B. Tango Argentino, West Coast Swing, spezifische Tanztechniken
          und Weiteres.{' '}
        </p>
        <p>
          Alle Workshops werden von Trainerinnen und Trainern mit hervorragenden Kenntnissen im
          entsprechendem Spezialgebiet durchgeführt.
          <br />
          Die Workshops finden in verschiedenen Leveln statt. Wer unsicher ist, ob ein Workshop für
          ihn passend ist, kann sich jederzeit zu unseren Bürozeiten mit uns in Verbindung setzen
          oder seine/n Tanzlehrer/in im Unterricht ansprechen.
        </p>
        <p>
          Preise und sonstige Informationen werden für die einzelnen Workshops gesondert
          ausgewiesen. Auch für Mitglieder im Premium-Clubsystem sind diese Workshops
          kostenpflichtig.
        </p>
        <br />
        <p>
          Die einzelnen Workshops finden nur bei genügend Reservierungen statt. <br />
          <b>
            <br />
            Bei einem <span style={{ color: '#fc0' }}>gelben</span> Ampelsymbol ist der gewünschte
            Workshop buchbar, aber die Mindestteilnehmerzahl, damit der Kurs stattfinden kann, noch
            nicht erreicht.
            <br />
            Ein stattfindender Workshop ist durch das <span style={{ color: 'green' }}>
              grüne
            </span>{' '}
            Symbol gekennzeichnet. Auch für stattfindende{' '}
            <span style={{ color: 'green' }}>(grüne)</span> Workshops ist eine Anmeldung zwingend
            notwendig.
            <br />
            <span style={{ color: 'red' }}>Rot</span> gekennzeichnete Workshops werden nicht
            stattfinden.
          </b>
          <br />
          <br />
          <p>
            Anmeldeschluss ist jeweils {config.DAYS_REGISTRATION_DEADLINE_WORKSHOP} Tage vor
            Workshopbeginn.{'\n'}
            In diesen {config.DAYS_REGISTRATION_DEADLINE_WORKSHOP} Tagen vor Workshopbeginn ist auch
            eine kostenfreie Stornierung der Teilnahme bei bereits erfolgter Reservierung nicht mehr
            möglich.
          </p>
        </p>
        <p>Eine Anmeldung ist nur paarweise möglich.</p>
        <p className={styles.color}>Änderungen vorbehalten!</p>
      </div>
    );
  }
  if (pageTitle === 'TCE-Sonderkurse') {
    return (
      <div>
        <p>
          Unsere TCE-Sonderkurse beschäftigen sich intensiv mit einzelnen Tänzen aus der
          vielfältigen Welt der Tänze. <br />
          Auf dem Programm stehen z.B. Salsa, Discofox, West Coast Swing und weitere.{' '}
        </p>
        <p>
          Die Unterrichtszeit beträgt 60 Minuten + 30 Minuten Coaching beim anschließenden Üben des
          Gelernten.{' '}
        </p>
        <p className={styles.onlyDesktop}>
          <div className={styles.flex}>
            Unsere Workshops finden in verschiedenen Leveln statt
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: '#333333'
                  }
                }
              }}
              title={
                <div className={styles.fontSize}>
                  <p>Der Schwierigkeitsgrad wird in Level eingeteilt:</p>
                  <p>
                    <div className={styles.level}>Beginner</div>
                    noch keine Tanzerfahrung mit diesem Tanz
                  </p>
                  <p>
                    <div className={styles.level}>Level 1</div>
                    Grundkenntnisse sind vorhanden und können sicher getanzt werden
                  </p>
                  <p>
                    <div className={styles.level}>Level 2</div>
                    Erlernen von fortgeschrittenen Elementen
                  </p>
                  <p>
                    <div className={styles.level}>Level 3 und 4</div>
                    Kurse für Paare mit sehr guten und mehrjährigen Tanzerfahrungen in den
                    jeweiligen Tänzen.
                  </p>
                </div>
              }
              placement={'right'}
              arrow
              enterTouchDelay={0}>
              <InfoOutlined className={styles.color} />
            </Tooltip>
          </div>
        </p>
        <p className={styles.onlyMobile}>
          <p>Der Schwierigkeitsgrad wird in Level eingeteilt:</p>
          <p>
            <div className={styles.level}>Beginner</div>
            noch keine Tanzerfahrung mit diesem Tanz
          </p>
          <p>
            <div className={styles.level}>Level 1</div>
            Grundkenntnisse sind vorhanden und können sicher getanzt werden
          </p>
          <p>
            <div className={styles.level}>Level 2</div>
            Erlernen von fortgeschrittenen Elementen
          </p>
          <p>
            <div className={styles.level}>Level 3 und 4</div>
            Kurse für Paare mit sehr guten und mehrjährigen Tanzerfahrungen in den jeweiligen
            Tänzen.
          </p>
        </p>
        <p>
          Preis für Mitglieder im Premium Club-System: {config.TCECOURSES_PRICE_MEMBER_PREMIUM}{' '}
          <br />
          Preis für alle anderen Mitglieder der Tanzschule: {config.TCECOURSES_PRICE_MEMBER} <br />
          Preis für Nicht-Mitglieder: {config.TCECOURSES_PRICE_NOT_MEMBER}
        </p>
        <p>
          Die einzelnen TCE-Sonderkurse finden nur bei genügend Reservierungen statt. <br />
          <b>
            <br />
            Bei einem <span style={{ color: '#fc0' }}>gelben</span> Ampelsymbol ist der gewünschte
            Kurs buchbar, aber die Mindestteilnehmerzahl, damit der Kurs stattfinden kann, noch
            nicht erreicht.
            <br />
            Ein stattfindender Kurs ist durch das <span style={{ color: 'green' }}>grüne</span>{' '}
            Symbol gekennzeichnet. Auch für stattfindende{' '}
            <span style={{ color: 'green' }}>(grüne)</span> Kurse ist eine Anmeldung zwingend
            notwendig.
            <br />
            <span style={{ color: 'red' }}>Rot</span> gekennzeichnete Kurse werden nicht
            stattfinden.
          </b>
        </p>
        <br />
        <p>
          {' '}
          Anmeldeschluss ist jeweils {config.DAYS_REGISTRATION_DEADLINE_TCECOURSES} Tage vor
          Kursbeginn.{'\n'}
          In diesen {config.DAYS_REGISTRATION_DEADLINE_TCECOURSES} Tagen vor Kursbeginn ist auch
          eine kostenfreie Stornierung der Teilnahme bei bereits erfolgter Reservierung nicht mehr
          möglich.
        </p>
        <p>Eine Anmeldung ist nur paarweise möglich.</p>
        <p className={styles.color}>Änderungen vorbehalten!</p>
        <p className={styles.kleinGedrucktes}>
          *die Preise verstehen sich bei Lastschrifteinzug. <br />
          Bei Barzahlung/Überweisung erhöht sich durch den größeren Verwaltungsaufwand der Preis um{' '}
          {config.TCECOURSES_EXTRA_BAR}.
        </p>
      </div>
    );
  }
  if (pageTitle === 'TCE-Events') {
    return (
      <div>
        <p>
          Unsere Veranstaltungen sind ein beliebter Treffpunkt, wo man mit anderen netten Leuten
          sowohl das Tanzbein schwingen, als auch bei Essen & Trinken gute Gespräche und ein
          geselliges Miteinander erleben kann.
          <br />
          Ein rechtzeitiges Reservieren sichert Plätze!
        </p>
        <p> Die Infos & Preise werden für die einzelnen Veranstaltungen gesondert ausgewiesen.</p>
        <p> Auch für Mitglieder im Premium-Clubsystem sind diese Events kostenpflichtig.</p>
        <p>
          <b>
            <br />
            Bei einem <span style={{ color: '#fc0' }}>gelben</span> Ampelsymbol ist die gewünschte
            Jugendparty buchbar, aber die Mindestteilnehmerzahl, damit die Party stattfinden kann,
            noch nicht erreicht.
            <br />
            Eine stattfindende Party ist durch das <span style={{ color: 'green' }}>
              grüne
            </span>{' '}
            Symbol gekennzeichnet.
            <br />
            <span style={{ color: 'red' }}>Rot</span> gekennzeichnete Partys werden nicht
            stattfinden.
          </b>
          <br />
          Anmeldeschluss ist jeweils {config.DAYS_REGISTRATION_DEADLINE_EVENT} Tage vor Beginn.
          {'\n'}
          In diesen {config.DAYS_REGISTRATION_DEADLINE_EVENT} Tagen vor Beginn ist auch eine
          kostenfreie Stornierung der Teilnahme bei bereits erfolgter Reservierung nicht mehr
          möglich.
        </p>
        <p>Eine Anmeldung ist nur paarweise möglich.</p>
        <p className={styles.color}>Änderungen vorbehalten!</p>
      </div>
    );
  }
  if (pageTitle === 'Jugend') {
    return (
      <div>
        <h3>Jugendparty.</h3>
        <p>
          Preis für Mitglieder im Premium Club-System:{' '}
          <span className={styles.color}>{config.JUGENDPARTY_PRICE_MEMBER_PREMIUM}</span>
          <br />
          Preis für alle anderen Mitglieder der Tanzschule:{' '}
          <span className={styles.color}>{config.JUGENDPARTY_PRICE_MEMBER}</span>
          <br />
          Preis für Nicht-Mitglieder:{' '}
          <span className={styles.color}>{config.JUGENDPARTY_PRICE_NOT_MEMBER}</span>
          <b>
            <br />
            Bei einem <span style={{ color: '#fc0' }}>gelben</span> Ampelsymbol ist die gewünschte
            Jugendparty buchbar, aber die Mindestteilnehmerzahl, damit die Party stattfinden kann,
            noch nicht erreicht.
            <br />
            Eine stattfindende Party ist durch das <span style={{ color: 'green' }}>
              grüne
            </span>{' '}
            Symbol gekennzeichnet.
            <br />
            <span style={{ color: 'red' }}>Rot</span> gekennzeichnete Partys werden nicht
            stattfinden.
          </b>
          <br />
          <br />
          <span className={styles.color}>Änderungen vorbehalten!</span>
        </p>
      </div>
    );
  }
  return <div />;
}
