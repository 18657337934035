import styles from './InternTanzabendDetails.module.css';
import toLocalDateString from '../../../../util/toLocalDateString';
import { FerienDTO } from '../../../../model/FerienDTO';
import IconButton from '@mui/material/IconButton/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { NavigateFunction } from 'react-router-dom';

type FerienDetailProps = {
  ferien: FerienDTO;
  navigate: NavigateFunction;
};

export default function InternFerienDetails({ ferien, navigate }: FerienDetailProps) {
  return (
    <div className={styles.tanzabendDetailContainer}>
      <div className={styles.tanzabendDetailTimeContainer}>
        <p style={{ color: '#ffcc00' }}>{ferien.title}</p>
        <p>:</p>
        <p>{toLocalDateString(ferien.startDate)}</p>
        <p> - </p>
        <p>{toLocalDateString(ferien.endDate)}</p>
        <IconButton onClick={() => navigate(`/intern/editHoliday/${ferien.id}`)}>
          <EditIcon />
        </IconButton>
      </div>
    </div>
  );
}
