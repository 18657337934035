import styles from '../features/Overview/Overview.module.css';
import { Divider, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import {
  CalendarMonth,
  Celebration,
  EmojiEvents,
  HomeRepairService,
  Lightbulb,
  People,
  Refresh,
  Search
} from '@mui/icons-material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfigContext } from '../context/ConfigContext';

const drawerItems = [
  {
    key: 'dates',
    label: 'Tanzabende und Ferien',
    icon: <CalendarMonth />,
    configKey: 'DATES_ENABLED_WEB',
    url: '/dates'
  },
  {
    key: 'youthDates',
    label: 'Jugend',
    icon: <Celebration />,
    configKey: 'YOUTH_DATES_ENABLED',
    url: '/jugend'
  },
  {
    key: 'clubfinder',
    label: 'Clubfinder',
    icon: <People />,
    configKey: 'CLUBFINDER_ENABLED',
    url: '/clubfinder'
  },
  {
    key: 'specialCourses',
    label: 'TCE-Kurse',
    icon: <Search />,
    configKey: 'TCECOURSES_ENABLED',
    url: '/courses'
  },
  {
    key: 'refresh',
    label: 'Refreshtanzen',
    icon: <Refresh />,
    configKey: 'REFRESH_ENABLED',
    url: '/refresh'
  },
  {
    key: 'tceevents',
    label: 'Events',
    icon: <EmojiEvents />,
    configKey: 'EVENTS_ENABLED',
    url: '/events'
  },
  {
    key: 'workshops',
    label: 'Workshops',
    icon: <HomeRepairService />,
    configKey: 'WORKSHOPS_ENABLED',
    url: '/workshops'
  },
  {
    key: 'membershipInfos',
    label: 'Informationen zur Mitgliedschaft',
    icon: <Lightbulb />,
    configKey: 'MEMBERSHIP_INFOS_ENABLED',
    url: '/membership-infos'
  }
];

export type DrawerNavigationProps = {
  close: () => unknown;
};

export default function DrawerNavigation({ close }: DrawerNavigationProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const config = useConfigContext();

  const onClick = (route: string) => {
    navigate(route);
    close();
  };

  return (
    <div className={styles.drawerContent}>
      <div>
        {drawerItems.map((item) => {
          if (config[item.configKey] === 'true') {
            return (
              <React.Fragment key={item.key}>
                <Divider />
                <ListItem
                  disablePadding
                  style={{
                    backgroundColor: location.pathname === item.url ? '#c0c0c0aa' : '#fc0'
                  }}>
                  <ListItemButton onClick={() => onClick(item.url)}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </ListItem>
              </React.Fragment>
            );
          }
          return null;
        })}
        <Divider />
      </div>
      <div>
        {config.T5_ENABLED === 'true' && (
          <>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  window.open('https://www.t5-event.de', '_blank', 'noreferrer');
                  close();
                }}>
                <ListItemIcon>{<HomeRepairService />}</ListItemIcon>
                <ListItemText primary={'Tanzschule mieten'} />
              </ListItemButton>
            </ListItem>
          </>
        )}
        <Divider />
        {config.ABOUT_ENABLED === 'true' && (
          <>
            <Divider />
            <ListItem
              disablePadding
              style={{
                backgroundColor: location.pathname === '/about' ? '#c0c0c0aa' : '#fc0'
              }}>
              <ListItemButton onClick={() => onClick('/about')}>
                <ListItemIcon>{<Lightbulb />}</ListItemIcon>
                <ListItemText primary={'Über tcevent'} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
        )}
      </div>
    </div>
  );
}
