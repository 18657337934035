import React, { PropsWithChildren, useState } from 'react';
import styles from './Overview.module.css';
import Signet from '../../assets/signet_tce.png';
import { useNavigate } from 'react-router-dom';
import { Close, Menu } from '@mui/icons-material';
import { Divider, Drawer } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton/IconButton';
import DrawerNavigation from '../../nav/DrawerNavigation';

function Overview({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <div>
      <header className={styles.overviewHeader}>
        <div className={styles.overviewImageContainer} onClick={() => navigate('/')}>
          <img alt="logo" src={Signet} />
          <h1>
            <span className={'yellowText'}>tce</span>
            <span className={'grayText'}>
              vent<span className={'whiteText'}>.</span>
            </span>
          </h1>
        </div>

        <nav>
          <ul>
            <li>
              {!drawerOpen ? (
                <Menu onClick={() => setDrawerOpen(!drawerOpen)} />
              ) : (
                <Close onClick={() => setDrawerOpen(!drawerOpen)} />
              )}
            </li>
          </ul>
        </nav>
      </header>
      <main className={styles.overviewMain}>
        <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} anchor={'right'}>
          <Box sx={{ width: 400, backgroundColor: '#ffcc00', height: '100vh' }} role="presentation">
            <div
              className={styles.drawerHeader}
              style={{
                padding: '2.5em',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'black'
              }}>
              <div
                className={styles.overviewImageContainer}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1em',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <img alt="logo" src={Signet} height={'50'} />
                <h3>
                  <span className={'yellowText'}>tce</span>
                  <span className={'grayText'}>
                    vent<span className={'whiteText'}>.</span>
                  </span>
                </h3>
              </div>
              <IconButton
                onClick={() => setDrawerOpen(false)}
                style={{ backgroundColor: '#aaaaaaaa', color: 'black' }}>
                <Close />
              </IconButton>
            </div>
            <Divider color={'white'} />
            <DrawerNavigation close={() => setDrawerOpen(false)} />
          </Box>
        </Drawer>
        {children}
      </main>
    </div>
  );
}

export default Overview;
